import React from 'react';

import Modal from '../../../components/Modal';
import img from '../../../images/city/miami_header.jpg';
import graphic from '../../../images/graphics/modal-mia.jpg';
import report from '../../../report/report.pdf';
const media = {
  secTitle: 'Miami, FL',
  cityModal: true,
  type: 'image',
  image: {
    src: img,
    alt: 'Miami, FL',
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      Miami is the cultural, economic and financial center of south Florida,
      with a diverse, international population. Miami’s metropolitan area is
      home to 6.1 million people and is the seventh-largest in the nation. Since
      1980, the population of people of color grew from 80.6 percent to 88.1
      percent of Miami’s population. The city is a majority-Latino city, with 70
      percent of its population being Latino. Cuban Americans still comprise
      over half of the city's population, at 54 percent. About 13 percent of
      Miami-Dade's Latinos are from Puerto Rico, the Dominican Republic and
      Mexico, and 32 percent are from other Central and South American countries
      such as Guatemala, Colombia, El Salvador, Venezuela, Nicaragua, Honduras
      and Brazil. Despite its overwhelming diversity, income inequality is
      prevalent. In Miami, the median hourly wage for white residents is $26
      while people of color only earn $13 comparatively. 41 percent of jobs
      require at least some college education. And, while 71 percent of the
      white population meets this requirement, only 18 percent of black and 48
      percent of Latino residents can compete. In Miami, we conducted three
      focus groups and heard the opinions of from college-educated black, white
      and Latino residents. Learn more by reading the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>

    <img
      src={graphic}
      alt="Bar charts and map of racial distribution in Miami, FL"
      className="Img-responsive"
    />
  </Modal>
);
